<script setup lang="ts">
  import { useToggle } from '@vueuse/core'

  const props = withDefaults(defineProps<{
    innerClass?: string
    open?: boolean
  }>(), {
    open: false
  })

  const [isOpened, toggle] = useToggle(props.open)
</script>

<template>
  <app-card
    class="collapsible-card"
    inner-class="u-pa--0">
    <button
      class="collapsible-card__header u-pa--400"
      type="button"
      @click="toggle()">
      <span class="collapsible-card__header-content">
        <slot name="header"/>
      </span>
      <span class="collapsible-card__toggle">
        <mdicon
          :name="isOpened ? 'chevron-up' : 'chevron-down'"
          size="36"/>
      </span>
    </button>
    <div v-auto-animate="{ duration: 150 }">
      <div
        :class="['collapsible-card__content', innerClass]"
        v-if="isOpened">
        <slot/>
      </div>
    </div>
  </app-card>
</template>

<style scoped>
  .collapsible-card__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
  }

  .collapsible-card__content {
    padding: var(--gap-400);
    &.faded {
      background-color: var(--c-background);
      margin-bottom: var(--gap-300);
      padding-top: var(--gap-200) !important;
      padding-bottom: var(--gap-200) !important;
      background-image: linear-gradient(to bottom, var(--c-background-light) 0, var(--c-background) var(--gap-400), var(--c-background) calc(100% - var(--gap-400)), var(--c-background-light));
    }
  }

  .collapsible-card__toggle {
    color: var(--c-primary);
  }
</style>
